import React from 'react';
import axios from 'axios';
import { chromeWebStoreVersionAPIEndPoint } from '../components/shared/constants';
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import Anchor from '../components/NewPageAnchorTag/NewPageAnchorTag';
import SiteLogo from '../images/unakite-icon.png';
import UnakiteRockImage from '../images/unakite-rock.jpg';
import NSFLogo from '../images/nsf_logo.png';
import './index.css';

class IndexPage extends React.Component {
  state = {
    chromeWebStoreVersion: '2'
  };

  componentDidMount() {
    axios
      .get(chromeWebStoreVersionAPIEndPoint)
      .then(response => {
        let versionString = response.data.chromeWebStoreVersion;
        this.setState({ chromeWebStoreVersion: versionString });
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    const { data } = this.props;

    return (
      <Layout pathName="Home">
        <div className="IndexPage">
          <div className="SectionZero" align="center">
            <div className="container">
              <div className="SectionZeroImgContainer">
                <img
                  alt=""
                  src={SiteLogo}
                  style={{
                    maxWidth: '200px',
                    width: '35%',
                    height: '35%',
                    borderRadius: '50%',
                    border: '3px solid white',
                    boxShadow: 'rgba(50, 50, 93, 0.2) 0px 1px 15px'
                  }}
                />
              </div>
              <br />
              <h2 style={{ color: 'black' }}>Welcome to Unakite.</h2>
              <div
                className="d-none d-sm-block"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Anchor
                  href={data.site.siteMetadata.downloadLink}
                  className="btn btn-outline-danger"
                >
                  Get Unakite Chrome Extension (v
                  {this.state.chromeWebStoreVersion})
                </Anchor>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Link
                  to="/docs/get-started/overview"
                  className="btn btn-outline-primary"
                >
                  Go to Docs
                </Link>
              </div>

              <div className="d-block d-sm-none">
                <div className="col-sm-12" style={{ marginBottom: 10 }}>
                  <Anchor
                    href={data.site.siteMetadata.downloadLink}
                    className="btn btn-outline-danger"
                  >
                    Get Unakite Chrome Extension
                  </Anchor>
                </div>
                <div className="col-sm-12" style={{ marginTop: 10 }}>
                  <Link
                    to="/docs/get-started/overview"
                    className="btn btn-outline-primary"
                  >
                    Go to Docs
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <br />

          <div className="IntroductionSection container">
            {/* Overview */}
            <h2>Overview</h2>
            <p>
              The <strong>Unakite</strong> Chrome extension is designed to help
              developers organize information forgaged from the web so that they
              can make better-informed programming decisions.
            </p>
            <p>
              You can use Unakite's{' '}
              <Link to="/docs/features/collecting">
                light-weight clipping tool
              </Link>{' '}
              to quickly snip any information into a{' '}
              <Link to="/docs/components/sidebar">sidebar</Link>. You can then
              start{' '}
              <Link to="/docs/features/organizing">
                organizing the information
              </Link>{' '}
              into a comparison table directly in the sidebar. After you make a
              decision, you can get a{' '}
              <Link to="/docs/features/sharing">sharable link</Link> to the
              table and the snippets and embed it in your code or{' '}
              <Link to="/docs/features/sharing">share</Link> it with your
              friends or colleagues.
            </p>

            <div className="row" style={{ marginBottom: 15 }}>
              <div
                className="col-3 col-sm-2 col-lg-1"
                style={{
                  paddingRight: 0,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <img
                  className="img-fluid align-self-center"
                  alt=""
                  src={UnakiteRockImage}
                />
              </div>
              <div
                className="col-9 col-sm-10 col-lg-11"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <p style={{ marginBottom: 0 }}>
                  <strong>Unakite</strong> stands for{' '}
                  <em>
                    <u>U</u>sers <u>N</u>eed <u>A</u>ccelerators for <u>K</u>
                    nowledge for <u>I</u>mplementations in <u>T</u>echnology{' '}
                    <u>E</u>nvironments
                  </em>
                  . And unakite is a semiprecious gemstone.
                </p>
              </div>
            </div>

            <p>
              The Chrome extension is currently in the development stage as a
              part of the{' '}
              <Anchor href="http://www.cs.cmu.edu/~NatProg/">
                Natural Programming Project
              </Anchor>{' '}
              as well as the{' '}
              <Anchor href="https://hcii.cmu.edu/research/knowledge-accelerator">
                {' '}
                Knowledge Accelerator Project
              </Anchor>
              .
            </p>

            <p>
              Team members:{' '}
              <Anchor href="http://lxieyang.github.io/">
                Michael Xieyang Liu
              </Anchor>
              , Jane Hsieh,{' '}
              <Anchor href="https://nhahn.org/">Nathan Hahn</Anchor>,{' '}
              <Anchor href="https://cs.oberlin.edu/~ctaylor/">
                Cynthia Taylor
              </Anchor>
              , <Anchor href="http://www.cs.cmu.edu/~bam/">Brad Myers</Anchor>,
              and <Anchor href="http://kittur.org/">Niki Kittur</Anchor>.
            </p>
            <br />

            {/* Publications */}
            <h2>Publications</h2>
            <ul>
              <li>
                Michael Xieyang Liu, Jane Hsieh, Nathan Hahn, Angelina Zhou,
                Emily Deng, Shaun Burley, Cynthia Taylor, Aniket Kittur, Brad A.
                Myers.{' '}
                <Anchor href="https://doi.org/10.1145/3332165.3347908">
                  Unakite: Scaffolding Developers’ Decision-Making Using the Web
                </Anchor>{' '}
                at{' '}
                <em>
                  ACM Symposium on User Interface Software and Technology (UIST)
                </em>
                , New Orleans, LA, USA, 2019, pp. 67-80.{' '}
                <strong>Best Paper Honorable Mention Award</strong>.
              </li>
              <li>
                Michael Xieyang Liu, Nathan Hahn, Angelina Zhou, Shaun Burley,
                Emily Deng, Jane Hsieh, Brad A. Myers and Aniket Kittur.
                "UNAKITE: Support Developers for Capturing and Persisting Design
                Rationales When Solving Problems Using Web Resources" at
                DTSHPS'18, Lisbon, Portugal, 2018.
              </li>
              <li>
                Jane Hsieh, Michael Xieyang Liu, Brad Myers and Aniket Kittur.{' '}
                <Anchor href="https://doi.org/10.1109/VLHCC.2018.8506517">
                  An Exploratory Study of Web Foraging to Understand and Support
                  Programming Decisions
                </Anchor>{' '}
                <em>
                  IEEE Symposium on Visual Languages and Human-Centric Computing
                  (VL/HCC)
                </em>
                , Lisbon, 2018, pp. 305-306.
              </li>
            </ul>

            {/* Funding */}
            <h2>Funding</h2>
            <div className="row">
              <div
                className="col-3 col-sm-2 col-lg-1"
                style={{
                  paddingRight: 0,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <img
                  className="img-fluid align-self-center"
                  alt=""
                  src={NSFLogo}
                />
              </div>
              <div
                className="col-9 col-sm-10 col-lg-11"
                style={{ display: 'flex', alignItems: 'flex-start' }}
              >
                <p style={{ marginBottom: 0 }}>
                  The Natural Programming Project has been funded in part by the
                  National Science Foundation (under the grant{' '}
                  <Anchor href="https://www.nsf.gov/awardsearch/showAward?AWD_ID=1814826">
                    CCF-1814826
                  </Anchor>
                  ) and in part by Google (under two Faculty research awards),
                  Bosch, and the CMU Center for Knowledge Acceleration.
                </p>
              </div>
            </div>
            <br />
          </div>
        </div>
      </Layout>
    );
  }
}

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
        downloadLink
      }
    }
  }
`;

export default IndexPage;
